export const NAVBAR = {
  NAV_LINKS: [
    {href: '#why-ar', title: 'Why AR'},
    {href: '#how-we-work', title: 'How We Work'},
    {href: '#your-options', title: 'Your Options'},
    {href: '#expertise', title: 'Expertise'},
    {href: '#portfolio', title: 'Portfolio'},
    {href: '#contact-us', title: 'Contact Us'},
  ],
};

export const HEADER = {
  title: 'We leverage AR to improve customer experience',
  description:
    'We help businesses with effective marketing through better customer engagement and an improved digital presence.',
  buttonOneText: 'Schedule Trial',
  buttonTwoText: 'See it in action',
  scanMeImg: require('../../../assets/images/showcase/ar-vr/scan-me.png').default,
};

export const WHY_AR = {
  title: 'Why Augmented Reality?',
  description:
    'AR empowers you to increase digital engagement with your audience and boost your digital presence for better sales conversions',
  CARDS: [
    {
      imgSrc: require('../../../assets/images/showcase/ar-vr/why-ar-1.png').default,
      title: 'Space and Item Visualization',
      description:
        'Help users visualize products in their own space for a confidence winning experience.',
      checklist: [
        'Decorate a room using AR powered visualization tools',
        'Design and deploy your 3D products to AR enabled platforms',
        'Minimalistic and Guided product visualization experiences',
      ],
    },
    {
      imgSrc: require('../../../assets/images/showcase/ar-vr/why-ar-2.png').default,
      title: 'Improve Customer Experience',
      description:
        'Use immersive and visual marketing content to boost customer engagement with your brand.',
      checklist: [
        'Build digital engagement with your customers through immersive 3D content',
        'Re-define your marketing strategy through exciting interaction opportunities with customers',
        'Run innovative marketing and sales campaigns through interactive posts',
        'Quick and easy deployment with traditional marketing content',
      ],
      ltr: false,
    },
    {
      imgSrc: require('../../../assets/images/showcase/ar-vr/why-ar-3.png').default,
      title: 'Increased Audience Conversion and Sales',
      description: 'Harness the power of immersive technologies to increase product sales.',
      checklist: [
        'Drive better sale conversions through AR enabled products',
        'Facilitate online shopping through integrated AR solutions',
      ],
    },
    {
      imgSrc: require('../../../assets/images/showcase/ar-vr/why-ar-4.png').default,
      title: 'Immersive Education and Training',
      description:
        'Make education engaging and off-shore training effective through interactive AR/VR experiences.',
      checklist: [
        'Facilitate early development and learning through visual content and storytelling',
        'Streamline virtual training through AR interaction based solutions',
      ],
      ltr: false,
    },
  ],
};

export const HOW_WE_WORK = {
  title: 'How We Work',
  description:
    'Our framework is tailored to ensure that AR/VR experiences aligns with your business goals for maximum return',
  CARDS: [
    {
      title: 'Strategy and Market Approach',
      description:
        'We help you match your ideas with the most suitable solutions for the best results.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/how-we-work-1.svg').default,
    },
    {
      title: 'Preparation',
      description:
        'We translate your requirements into technical specifications. We also build 3D models and other content needed for your AR/VR solutions.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/how-we-work-2.svg').default,
    },
    {
      title: 'Development',
      description: 'We develop your solution using an agile approach.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/how-we-work-3.svg').default,
    },
    {
      title: 'Live Deployment',
      description: 'We deploy your solution and updates dynamically.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/how-we-work-4.svg').default,
    },
  ],
};

export const INDUSTRIES = {
  title: 'See AR in Action',
  description:
    'Our framework is tailored to ensure that AR/VR experiences aligns with your business goals for maximum return, across every industry',
  buttonText: 'See it in action',
  scanMeImg: require('../../../assets/images/showcase/ar-vr/scan-me-top.png').default,
  FURNITURE: {
    title: 'Furniture',
    color: '#9E9EA6',
    images: [
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-furniture-1.png').default,
        title: 'Furniture Idustry App V1',
        label: 'Select a product',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-furniture-2.png').default,
        title: 'Furniture Idustry App V2',
        label: 'Select a surface',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-furniture-3.png').default,
        title: 'Furniture Idustry App V3',
        label: 'Place your item',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-furniture-4.png').default,
        title: 'Furniture Idustry App V4',
        label: 'Edit & Buy',
      },
    ],
  },
  CLOTHING: {
    title: 'Clothing',
    color: '#9C667A',
    images: [
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-clothing-1.png').default,
        title: 'Clothing Idustry App V1',
        label: 'Select a product',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-clothing-2.png').default,
        title: 'Clothing Idustry App V2',
        label: 'Place your item',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-clothing-3.png').default,
        title: 'Clothing Idustry App V3',
        label: 'Rotate to view',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-clothing-4.png').default,
        title: 'Clothing Idustry App V4',
        label: 'Add to cart',
      },
    ],
  },
  FOOD_AND_BEVERAGE: {
    title: 'Food & Beverage',
    color: '#707E70',
    images: [
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-food-beverage-1.png')
          .default,
        title: 'Food & Beverage Idustry App V1',
        label: 'Open the camera',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-food-beverage-2.png')
          .default,
        title: 'Food & Beverage Idustry App V2',
        label: 'Zoom on product',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-food-beverage-3.png')
          .default,
        title: 'Food & Beverage Idustry App V3',
        label: 'Wait to scan',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-food-beverage-4.png')
          .default,
        title: 'Food & Beverage Idustry App V4',
        label: 'View details',
      },
    ],
  },
};

export const YOUR_OPTIONS = {
  title: 'What Are Your Options?',
  description:
    'Our flexible development options helps you build the solution most suited for your business needs',
  CARDS: [
    {
      title: 'Immersive App Development',
      description: 'A Mobile app for your business meant for sales or internal company use.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/your-options-1.svg').default,
    },
    {
      title: 'Online AR/VR solution development and integration',
      description:
        'AR delivered through your website to your customers or your team. No need to create or download an app.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/your-options-2.svg').default,
    },
    {
      title: 'Remote Teams / Team Augmentation',
      description:
        'Hire a team to work remotely with your tech department on specific tasks or projects.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/your-options-3.svg').default,
    },
  ],
};

export const AWARDS = {
  title: 'Awards & Recognitions',
};

export const OUR_EXPERTISE = {
  title: 'Our Expertise',
  CARDS: [
    {
      title: 'Full Stack Development',
      imgSrc: require('../../../assets/images/showcase/ar-vr/our-expertise-1.svg').default,
    },
    {
      title: 'Immersive Experience Design',
      imgSrc: require('../../../assets/images/showcase/ar-vr/our-expertise-2.svg').default,
    },
    {
      title: 'Product Management',
      imgSrc: require('../../../assets/images/showcase/ar-vr/our-expertise-3.svg').default,
    },
    {
      title: 'Multi-platform Integration',
      imgSrc: require('../../../assets/images/showcase/ar-vr/our-expertise-4.svg').default,
    },
  ],
};

export const FEATURED_PROJECTS = [
  {
    id: 'decorist',
    caseStudyUrl: '/portfolio/decorist-room-builder',
    featuredImageSrc: require('../../../assets/images/showcase/ar-vr/featured-projects/featured-images/decorist.png')
      .default,
    logoSrc: require('../../../assets/images/showcase/ar-vr/featured-projects/logos/decorist.png')
      .default,
    projectDescription: 'An online interior design service that matches you.',
    projectName: 'decorist',
    solutionCategory: 'Web Development',
  },
];

export const CONTACT_US = {
  title: 'Try Us Today!',
  description:
    'We create the 3D models and embed AR tech into your Website. Full-service, worldwide.',
  buttonText: 'Get In Touch',
};

export const FOOTER = {
  description:
    'Safir Solutions works with Digital Transformation by providing advisory services and digital products. We help you assess the need and scope of digitalisation and to prepare for organisational change that comes with it. Our digital products are designed with a user perspective.',
  copyright: `Copyright ${new Date().getFullYear()} Safir Solutions © All Rights Reserved`,
  contact: 'Contact',
  mailUs: 'Mail Us',
  contactUs: 'Contact Us',
};
